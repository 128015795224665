import { FC, ReactNode } from 'react';
import { mergeClasses } from 'utils';
import useMobile from '../../hooks/useMobile';

const imgWidth = 4;
const gapWidth = 2.5;
const mobileGapWidth = 1.125;
const calculateRailWidth = (itemsNumber: number) => {
  const width = itemsNumber * imgWidth + (itemsNumber - 1) * gapWidth;
  return width * 1.6;
};

const calculateMobileRailWidth = (itemsNumber: number) => {
  const width = itemsNumber * imgWidth + (itemsNumber - 1) * mobileGapWidth;
  return width * 1.5;
};

interface MarqueeProps {
  className?: string;
  itemsNumber: number;
  children: ReactNode[];
}

export const Marquee: FC<MarqueeProps> = ({ className = '', children, itemsNumber }) => {
  const { isMobile } = useMobile();

  return (
    <div
      className={mergeClasses('overflow-hidden relative', className)}
      style={{
        width: `${
          isMobile ? calculateMobileRailWidth(itemsNumber) : calculateRailWidth(itemsNumber)
        }rem`,
      }}
    >
      <div className="flex items-center w-[200%] animate-moveRight absolute hover:pause h-full">
        <span className="overflow-hidden float-left w-1/2 h-full">
          <div className="flex items-center gap-[1.125rem] md:gap-[2.5rem] max-h-full">
            {children}
          </div>
        </span>
        <span className="overflow-hidden float-left w-1/2 h-full">
          <div className="flex items-center gap-[1.125rem] md:gap-[2.5rem] max-h-full">
            {children}
          </div>
        </span>
      </div>
    </div>
  );
};
