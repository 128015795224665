import { IconType } from 'icons';
import { FC } from 'react';

export interface FooterSocial {
  name: string;
  icon: IconType;
  url: string;
}

interface FooterSocialsProps {
  socials: FooterSocial[];
}

export const FooterSocials: FC<FooterSocialsProps> = ({ socials }) => {
  return (
    <ul className="flex gap-[.875rem]">
      {socials.map(({ icon: Icon, url, name }) => (
        <li
          key={name}
          className="flex justify-center items-center text-light-blue-400 rounded-full"
        >
          <a href={url} rel="noopener noreferrer" target="_blank">
            <Icon className="w-[49px] h-[49px] bg-transparent" />
          </a>
        </li>
      ))}
    </ul>
  );
};
