import { ExpertDetailsType, ExpertTagType } from 'interfaces';

interface MapExpertSkillsParams {
  skillsMustHave: ExpertTagType[];
  skillsNiceToHave: ExpertTagType[];
  expertDetails: ExpertDetailsType;
}

export const mapExpertSkills = ({
  skillsMustHave,
  skillsNiceToHave,
  expertDetails,
}: MapExpertSkillsParams) => {
  if (!expertDetails) {
    return {
      expertMustHaveSkills: [],
      expertNiceToHaveSkills: [],
    };
  }
  const expertMustHaveSkills = expertDetails?.mustHaveSkillsLevels.reduce((acc, skillLevel) => {
    const matchingSkill = skillsMustHave.find((skill) => skill.id === skillLevel.id);
    if (matchingSkill) {
      acc.push({
        id: skillLevel.id,
        name: matchingSkill.name,
        weight: skillLevel.level,
      });
    }
    return acc;
  }, [] as Array<{ id: string; weight: number; name: string }>);

  const expertNiceToHaveSkills = expertDetails?.niceToHaveSkills.reduce((acc, expertSkill) => {
    const matchingSkill = skillsNiceToHave.find((skill) => skill.id === expertSkill);
    if (matchingSkill) {
      acc.push({
        id: matchingSkill.id,
        name: matchingSkill.name,
        weight: 0,
      });
    }
    return acc;
  }, [] as Array<{ id: string; name: string; weight: number }>);

  return {
    expertMustHaveSkills,
    expertNiceToHaveSkills,
  };
};
