import {
  ConnectisWebsiteFooter,
  ConnectisWebsiteHeader,
  ConnectisWebsiteHero,
  JobBoard,
} from 'components';
import { useLightBackgroundObserver } from 'hooks';
import { useEffect } from 'react';

export const JobBoardPage = () => {
  const { isLightBackground, measureDimensions } = useLightBackgroundObserver();

  useEffect(() => {
    const htmlEl = document.querySelector('html');
    if (!htmlEl) {
      return;
    }
    htmlEl.classList.add('job-board');
    return () => {
      htmlEl.classList.remove('job-board');
    };
  }, []);

  return (
    <main className="font-dmSans">
      <ConnectisWebsiteHeader isLightBackground={isLightBackground} />
      <ConnectisWebsiteHero />
      <section
        className="job-board-container flex justify-center mx-auto py-12 md:py-14 light-section"
        id="jobBoard"
      >
        <JobBoard onPublicOffersFetch={measureDimensions} />
      </section>
      <ConnectisWebsiteFooter />
    </main>
  );
};
