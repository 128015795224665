import { JobOrderList, Paper, ShowMore } from 'components';
import { JobOrdersQuerySortByEnum } from 'interfaces';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecommendedJobOrdersQuery } from '../../../../../hooks/api/recommendedJobOrders/recommendedJobOrders.generated';
import { useDynamicShowMoreLabel } from '../../../../../hooks/useDynamicShowMoreLabel';

const { CreatedAtAsc, CreatedAtDesc, ...otherSortTypes } = JobOrdersQuerySortByEnum;
export const sortTypes = Object.values(otherSortTypes);

export const RecommendedOffers = () => {
  const { t } = useTranslation();

  const { data, loading } = useRecommendedJobOrdersQuery({ fetchPolicy: 'cache-and-network' });
  const jobOrders = data?.recommendedJobOrders ?? [];

  const listRef = useRef<HTMLDivElement | null>(null);

  const initialHeight = 1106;

  const { showMoreValue, updateListHeightExternally } = useDynamicShowMoreLabel(
    listRef,
    initialHeight,
  );

  useEffect(() => {
    updateListHeightExternally();
  }, [updateListHeightExternally]);

  return (
    <Paper className="relative" title={t('offers:recommendedOffers.title')}>
      <ShowMore
        hiddenHeight={initialHeight}
        isHiddenPadding="pb-8 -mb-3"
        isShowedPadding="pb-16 lg:pb-8 -mb-10"
        numberOfListItems={showMoreValue}
        overlayClassName="-mx-8  w-[calc(100%+64px)]"
      >
        <div ref={listRef}>
          <JobOrderList
            isRecommendedOffers
            jobOrders={jobOrders}
            loading={loading}
            name="recommendedJobOrders"
          />
        </div>
      </ShowMore>
    </Paper>
  );
};
