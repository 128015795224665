import { useReactiveVar } from '@apollo/client';
import { SmallButton } from 'components';
import { CrossedEyeIcon, EyeIcon } from 'icons';
import { JobOrderQueryType } from 'interfaces';
import { Dispatch, FC, SetStateAction, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { profileVar } from 'reactive-vars';
import { EnvironmentConfig } from '../../../../environment-config';
import { mapExpertSkills } from '../utils';
import { SkillGrid } from './SkillGrid';

interface JobOrderDetailsHeaderProps {
  jobOrder: JobOrderQueryType | null;
  setAreTechnicalDetailsOpen: Dispatch<SetStateAction<boolean>>;
  areTechnicalDetailsVisible: boolean;
}

export const JobOrderDetailsTechnologies: FC<JobOrderDetailsHeaderProps> = ({
  jobOrder,
  setAreTechnicalDetailsOpen,
  areTechnicalDetailsVisible,
}) => {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (!jobOrder) {
      return;
    }
    const shouldBeExpanded = jobOrder.score.finalScore < EnvironmentConfig.minimalScoreToApply;
    setAreTechnicalDetailsOpen(shouldBeExpanded);
  }, [jobOrder]);

  const { skillsMustHave, skillsNiceToHave } = jobOrder ?? {
    skillsMustHave: [],
    skillsNiceToHave: [],
  };

  const expertDetails = jobOrder?.score.expertDetails;

  const { expertMustHaveSkills, expertNiceToHaveSkills } = useMemo(() => {
    return mapExpertSkills({
      skillsMustHave,
      skillsNiceToHave,
      expertDetails,
    });
  }, [skillsMustHave, skillsNiceToHave, expertDetails]);
  const profileTags = useReactiveVar(profileVar)?.tags;

  const matchingMustHaveSkills = useMemo(() => {
    return (expertMustHaveSkills || profileTags || []).filter((profileTag) =>
      skillsMustHave.some((mustHaveTag) => mustHaveTag.id === profileTag.id),
    );
  }, [expertMustHaveSkills, profileTags, skillsMustHave]);

  const matchingNiceToHaveSkills = useMemo(() => {
    return (expertNiceToHaveSkills || profileTags || []).filter((profileTag) =>
      skillsNiceToHave.some((niceToHaveTag) => niceToHaveTag.id === profileTag.id),
    );
  }, [expertNiceToHaveSkills, profileTags, skillsNiceToHave]);

  const buttonLabel = areTechnicalDetailsVisible
    ? t('forms:labels.hideCommonSkills')
    : t('forms:labels.showCommonSkills');

  const buttonIcon = areTechnicalDetailsVisible ? CrossedEyeIcon : EyeIcon;

  return (
    <div className="pt-8 relative">
      <p className="font-bold mb-6 md:mb-8 lg:text-[18px]">{t('forms:labels.skills')}</p>
      <SmallButton
        icon={buttonIcon}
        iconClassName="!mr-1 !transition-all !duration-300 !ease-in-out"
        label={buttonLabel}
        labelClassName="font-medium text-[14px] md:text-[16px] absolute right-0 top-8 !transition-all !duration-300 !ease-in-out"
        onClick={() => {
          setAreTechnicalDetailsOpen(!areTechnicalDetailsVisible);
        }}
        variant="primary"
      />

      {!!skillsMustHave.length && (
        <SkillGrid
          areTechnicalDetailsVisible={areTechnicalDetailsVisible}
          isInModal
          isPrimary
          matchingMustHaveSkills={matchingMustHaveSkills}
          skills={skillsMustHave}
          title={t('forms:labels.required')}
        />
      )}

      {!!skillsNiceToHave.length && (
        <SkillGrid
          areTechnicalDetailsVisible={areTechnicalDetailsVisible}
          className="mt-8 md:mt-12"
          isInModal
          matchingNiceToHaveSkills={matchingNiceToHaveSkills}
          noLevelSkill
          skills={skillsNiceToHave}
          title={t('forms:labels.niceToHave')}
        />
      )}
    </div>
  );
};
