import { JobOrderList, Paper, Select, ShowMore, SmallButton } from 'components';
import { useModalState } from 'hooks';
import { SortingIcon } from 'icons';
import { MouseEventHandler, Ref, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useJobOrdersUnderMinScoreToApplyQuery } from '../../../../../hooks/api/jobOrdersUnderMinScoreToApply/jobOrdersUnderMinScoreToApply.generated';
import { useDynamicShowMoreLabel } from '../../../../../hooks/useDynamicShowMoreLabel';
import type { ControlComponentType } from '../../../../Inputs/Select/Select';
import SortModal from '../../../../Modals/SortModal/SortModal';
import { sortTypes } from './RecommendedOffers';

export const ButtonComponent: ControlComponentType = ({ innerRef, innerProps, children }) => {
  const { t } = useTranslation();

  return (
    <SmallButton
      ref={innerRef as Ref<HTMLButtonElement>}
      className="justify-end w-full relative"
      icon={SortingIcon}
      iconClassName="text-gray-400 mr-1.5"
      label={t('forms:placeholders.sort')}
      labelClassName="text-[16px] leading-[20px] text-black font-medium"
      onMouseDown={innerProps.onMouseDown as unknown as MouseEventHandler<HTMLButtonElement>}
      onTouchEnd={innerProps.onTouchEnd}
    >
      <p className="opacity-0 absolute">{children}</p>
    </SmallButton>
  );
};

export const OtherOffers = () => {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState(sortTypes[0]);

  const listRef = useRef<HTMLDivElement | null>(null);

  const sortOptions = sortTypes.map((sort) => ({
    label: t(`dictionaries:offersSorting.${sort}`) || sort,
    value: sort,
  }));

  const {
    modalState: sortModalState,
    closeModal: closeSortModal,
    openModal: openSortModal,
  } = useModalState<null>();

  const { data, loading } = useJobOrdersUnderMinScoreToApplyQuery({
    variables: {
      params: {
        sortBy,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const jobOrders = data?.jobOrdersUnderMinScoreToApply ?? [];

  const initialHeight = 1106;

  const { showMoreValue, updateListHeightExternally } = useDynamicShowMoreLabel(
    listRef,
    initialHeight,
  );

  useEffect(() => {
    updateListHeightExternally();
  }, [updateListHeightExternally]);

  return (
    <>
      <SortModal
        isOpen={sortModalState.isOpen}
        onClose={closeSortModal}
        setSortBy={setSortBy}
        sortOptions={sortOptions}
      />
      <Paper className="relative !pb-4" title={t('offers:otherOffers.title')}>
        <SmallButton
          className="flex lg:hidden absolute right-4 top-[18px]"
          icon={SortingIcon}
          iconClassName="text-gray-400 mr-1.5"
          label={t('forms:placeholders.sort')}
          labelClassName="text-[16px] leading-[20px] text-black font-medium"
          onClick={() => openSortModal(null)}
        />
        <div className="hidden lg:flex absolute right-8 top-9">
          <Select
            className="max-w-[305px] md:w-[305px]"
            customControlComponent={ButtonComponent}
            name="sort"
            onChange={setSortBy}
            options={sortOptions}
            value={sortBy}
          />
        </div>
        <ShowMore
          hiddenHeight={initialHeight}
          isHiddenPadding="pb-8 -mb-3"
          isShowedPadding="pb-16 lg:pb-8 -mb-12"
          numberOfListItems={showMoreValue}
          overlayClassName="-mx-8 w-[calc(100%+64px)]"
        >
          <div ref={listRef}>
            <JobOrderList
              jobOrders={jobOrders}
              loading={loading}
              name="otherJobOrders"
              sortBy={sortBy}
            />
          </div>
        </ShowMore>
      </Paper>
    </>
  );
};
