import avivaSrc from 'assets/images/job-board/trustedCompanies/aviva.png';
import bmwSrc from 'assets/images/job-board/trustedCompanies/bmw.png';
import bshSrc from 'assets/images/job-board/trustedCompanies/bsh.avif';
import circleSrc from 'assets/images/job-board/trustedCompanies/circle-k.avif';
import fujitsuSrc from 'assets/images/job-board/trustedCompanies/fujitsu.png';
import heinekenSrc from 'assets/images/job-board/trustedCompanies/heineken.png';
import ikeaSrc from 'assets/images/job-board/trustedCompanies/ikea.png';
import sapSrc from 'assets/images/job-board/trustedCompanies/sap.avif';
import vattenfallSrc from 'assets/images/job-board/trustedCompanies/vattenfall.png';

export const trustedCompanies = [
  {
    src: circleSrc,
    name: 'circle',
  },
  {
    src: vattenfallSrc,
    name: 'vattenfall',
  },
  {
    src: sapSrc,
    name: 'sap',
  },
  {
    src: bshSrc,
    name: 'bsh',
  },
  {
    src: bmwSrc,
    name: 'bmw',
  },
  {
    src: heinekenSrc,
    name: 'heineken',
  },
  {
    src: fujitsuSrc,
    name: 'fujitsu',
  },
  {
    src: ikeaSrc,
    name: 'ikea',
  },
  {
    src: avivaSrc,
    name: 'aviva',
  },
];
