import * as Types from '../../../interfaces/api.types.generated.d';

import { gql } from '@apollo/client';
export type ExpertDetailsFragmentFragment = {
  __typename?: 'CalculateScoreResultExpertDetailsType';
  niceToHaveSkills: Array<string>;
  englishLanguageLevel?: Types.LanguageLevelEnum | null;
  workMode?: Types.WorkModeEnum | null;
  availability?: Types.ExpertAvailabilityEnum | null;
  noticePeriod?: number | null;
  rateDesired?: number | null;
  mustHaveSkillsLevels: Array<{ __typename?: 'MustHaveSkillLevel'; id: string; level: number }>;
  roles: Array<{ __typename?: 'ExpertRoleType'; jobPositionId: string; yearsInRole: number }>;
};

export const ExpertDetailsFragmentFragmentDoc = gql`
  fragment ExpertDetailsFragment on CalculateScoreResultExpertDetailsType {
    mustHaveSkillsLevels {
      id
      level
    }
    niceToHaveSkills
    englishLanguageLevel
    roles {
      jobPositionId
      yearsInRole
    }
    workMode
    availability
    noticePeriod
    rateDesired
  }
`;
