import { useTranslation } from 'react-i18next';
import { ConnectisWebsiteHeroTrustedUs } from './subcomponents';

export const ConnectisWebsiteHero = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'connectisWebsite:hero',
  });

  return (
    <section className="bg-[url('assets/images/job-board/job-board-hero.png')] bg-cover bg-center lg:bg-top h-[810px] max-h-[66dvh] min-h-[450px] overflow-hidden">
      <div className="w-full h-full bg-gradient-black-transparent">
        <div className="job-board-container !box-border lg:!box-content mx-auto relative h-full flex flex-col justify-between text-[#eaeae9] pb-[8.3125rem] lg:pb-0">
          <p className="text-base pt-[7rem] lg:pt-[10.375rem] pb-6">{t('jobOffers')}</p>
          <div className="pb-[5rem]">
            <h1 className="inline-block text-5xl md:text-[4rem] md:leading-[1.1] mb-6 bg-gradient-neon-green-down bg-clip-text text-transparent">
              {t('title')}
            </h1>
            <p className="text-base md:text-2xl leading-[1.3]">{t('subtitle')}</p>
          </div>
          <ConnectisWebsiteHeroTrustedUs />
        </div>
      </div>
    </section>
  );
};
