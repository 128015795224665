import { JobOrderList, Paper } from 'components';
import { t } from 'i18next';
import { useSavedJobOrdersQuery } from '../../../../hooks/api/savedJobOrders/savedJobOrders.generated';

export const SavedOffersTab = () => {
  const { data, loading } = useSavedJobOrdersQuery({ fetchPolicy: 'cache-and-network' });
  const savedJobOrders = data?.savedJobOrders ?? [];

  return (
    <Paper
      className="lg:relative lg:-top-20 xl:-top-24"
      title={t('offers:tabs.saved')}
      titleClassName="!mb-2"
    >
      <JobOrderList jobOrders={savedJobOrders} loading={loading} name="savedJobOrders" />
    </Paper>
  );
};
