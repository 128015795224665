import { IconButton } from 'components';
import { ThinArrowIcon } from 'icons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { ConnectisWebsiteMenu } from '../../configs/menu';

export interface FooterLink {
  url: string;
  labelKey: string;
}

export const FooterMenu: FC<ConnectisWebsiteMenu> = ({ name, items }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: `connectisWebsite:menus.${name}`,
  });
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div>
      <div className="flex justify-between md:mb-4" onClick={() => setCollapsed((prev) => !prev)}>
        <p className="font-medium lg:font-bold text-white whitespace-nowrap text-base">
          {t('headingFooter', {
            defaultValue: t(`heading`),
          })}
        </p>
        <IconButton
          className={mergeClasses(
            'text-light-blue-300 transition-transform duration-150 md:hidden',
            collapsed ? 'rotate-90' : '-rotate-90',
          )}
          icon={ThinArrowIcon}
        />
      </div>
      <ul
        className={mergeClasses(
          'overflow-hidden border-b-[.0625rem] md:border-none md:pt-0 transition-all duration-300 md:max-h-full w-full lg:max-w-[11rem] border-b-[#5c69bf]',
          collapsed ? 'max-h-0' : 'max-h-52 pb-2',
        )}
      >
        {items.map(({ url, name: itemName }) => (
          <li
            key={`footerMenuItem_${name}_${itemName}`}
            className="text-sm text-light-blue-300 py-[.375rem]"
          >
            <a href={url}>
              {t(`items.${itemName}.footerLabel`, {
                defaultValue: t(`items.${itemName}.label`),
              })}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
